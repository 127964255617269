body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

a:focus {
  outline: thin dotted; }

a:active, a:hover {
  outline: 0; }

h1 {
  font-size: 2em; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: 700; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

code, kbd, pre, samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: \201C \201D \2018 \2019; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em; }

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button, input {
  line-height: normal; }

button, html input[type=button], input[type=reset], input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled], input[disabled] {
  cursor: default; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

input[type=search] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

body, figure {
  margin: 0; }

legend, button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: '';
  clear: both;
  height: 0; }

* {
  box-sizing: border-box; }

/* Default styles */
img {
  max-width: 100%;
  width: 100%;
  display: block; }

a {
  text-decoration: none;
  color: #0e0d0d; }
  a:focus {
    outline: none; }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, p, blockquote {
  margin: 0; }

/* Wrapper styles */
.wrapper {
  max-width: 1600px;
  width: 95%;
  margin: 0 auto; }

/* Font and Background Styles */
html {
  font-size: 125%; }

body {
  line-height: 1.6;
  background: white;
  font-family: "Poppins", sans-serif;
  color: #0e0d0d; }

/* Font styles */
h1 {
  font-family: "Chonburi", cursive;
  font-size: 5rem;
  color: #FF4500;
  text-align: center;
  letter-spacing: 4px;
  margin-top: 20px; }
  @media (max-width: 900px) {
    h1 {
      font-size: 3rem;
      padding-top: 50px; } }
  @media (max-width: 600px) {
    h1 {
      font-size: 2rem; } }
  @media (max-width: 375px) {
    h1 {
      font-size: 1.8rem; } }

h2 {
  font-size: 3rem;
  color: #FF4500;
  font-weight: 700;
  letter-spacing: 2px; }
  @media (max-width: 900px) {
    h2 {
      font-size: 2rem; } }

h3 {
  font-size: 1.7rem;
  color: #282626;
  font-weight: 700;
  letter-spacing: 2px; }
  @media (max-width: 600px) {
    h3 {
      font-size: 1.08rem; } }

p {
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 20px 0; }
  @media (max-width: 600px) {
    p {
      font-size: 1rem; } }

blockquote {
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 40px; }
  @media (max-width: 600px) {
    blockquote {
      font-size: 1.4rem; } }
  @media (max-width: 375px) {
    blockquote {
      font-size: 1.1rem; } }

.bold {
  font-weight: 600; }

.italic {
  font-style: italic;
  font-weight: 600;
  letter-spacing: 2px; }

.smallest-font {
  font-size: 0.8rem; }
  @media (max-width: 900px) {
    .smallest-font {
      text-align: center; } }

.italic-not-bolded {
  font-style: italic; }

.underline {
  border-bottom: 2px solid #FF4500;
  padding: 0 20px; }

.page-heading {
  padding: 0 20px;
  border-bottom: 2px solid #FF4500; }

.link {
  font-weight: 700;
  padding: 3px 0;
  border-bottom: 2px solid transparent; }
  .link:hover {
    border-bottom: 2px solid #FF4500; }
  .link:focus, .link:active {
    color: #FF4500; }

.activeLink {
  color: green; }

.larger-para {
  font-weight: 700;
  font-size: 1.3rem;
  letter-spacing: 2px; }
  @media (max-width: 600px) {
    .larger-para {
      font-size: 0.9rem; } }

.quote-attribute {
  text-align: right;
  margin-top: 10px; }
  @media (max-width: 600px) {
    .quote-attribute {
      font-size: 0.9rem; } }

.list-with-bullets {
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 15px; }

nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  min-height: 10vh;
  -webkit-align-items: center;
          align-items: center; }
  @media (max-width: 900px) {
    nav {
      min-height: auto; } }
  nav .nav-list {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center; }
    @media (max-width: 900px) {
      nav .nav-list {
        -webkit-flex-direction: column;
                flex-direction: column;
        z-index: 19;
        position: fixed;
        height: 100vh;
        background: white;
        right: -100%;
        top: 0;
        -webkit-align-items: center;
                align-items: center;
        transition: all 300ms; } }
  nav .nav-list-item {
    padding: 40px 0; }
  @media (max-width: 900px) {
    nav .show-nav-list {
      right: 0; } }

.nav-link {
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 5px 20px;
  margin: 0 10px;
  border-bottom: 2px solid transparent; }
  .nav-link:hover {
    border-bottom: 2px solid #FF4500; }
  .nav-link:focus, .nav-link:active {
    border-color: #FF4500; }
  @media (max-width: 900px) {
    .nav-link {
      font-size: 2.4rem;
      margin: 0; } }
  @media (max-width: 600px) {
    .nav-link {
      font-size: 1.2rem; } }

.hamburger-menu {
  display: none;
  border: 2px solid transparent;
  border-radius: 50%;
  background: transparent;
  padding: 15px;
  position: absolute;
  right: 2%;
  top: 2%; }
  .hamburger-menu:active, .hamburger-menu:focus {
    border-color: #FF4500; }
  .hamburger-menu .menu-bar {
    border-radius: 2px;
    display: block;
    margin: 3px auto;
    width: 30px;
    height: 5px;
    background: #FF4500;
    transition: opacity 1ms, -webkit-transform 500ms;
    transition: transform 500ms, opacity 1ms;
    transition: transform 500ms, opacity 1ms, -webkit-transform 500ms; }
  @media (max-width: 900px) {
    .hamburger-menu {
      display: block;
      z-index: 20; } }

.transform-bar1 {
  -webkit-transform: rotate(45deg) translate(3px, 4px);
          transform: rotate(45deg) translate(3px, 4px);
  border-radius: 0; }

.transform-bar2 {
  -webkit-transform: rotate(-45deg) translate(2.5px, -2px);
          transform: rotate(-45deg) translate(2.5px, -2px);
  border-radius: 0; }

.transform-bar3 {
  opacity: 0; }

.active-link {
  color: #FF4500; }

footer {
  margin-top: 40px;
  border-top: 1px solid #FF4500;
  padding-top: 40px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  footer .footer-credits {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center; }
    @media (max-width: 900px) {
      footer .footer-credits {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center; } }
  footer .footer-contact {
    margin: 60px 0;
    text-align: center; }

.credits-container {
  display: -webkit-flex;
  display: flex; }
  @media (max-width: 900px) {
    .credits-container {
      margin-top: 10px; } }
  @media (max-width: 600px) {
    .credits-container {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
      margin: 0; } }

.connect-with-maria {
  padding: 15px;
  border: 5px double #FF4500;
  border-radius: 5px;
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: 2px;
  position: relative;
  transition: color 500ms, border-radius 300ms; }
  .connect-with-maria:hover {
    color: #FF4500; }
  .connect-with-maria:active, .connect-with-maria:focus {
    border-radius: 40px; }
  @media (max-width: 600px) {
    .connect-with-maria {
      font-size: 1rem; } }

.divider {
  margin: 0 10px; }
  @media (max-width: 600px) {
    .divider {
      display: none; } }

@media (max-width: 900px) {
  .copyright .divider {
    display: none; } }

.footer-para {
  font-size: 0.9rem;
  margin: 0; }
  @media (max-width: 600px) {
    .footer-para {
      margin: 7px 0; } }

.about-container {
  width: 100%; }

.maria-about-headshot {
  width: 35%;
  float: left;
  padding-right: 40px; }
  .maria-about-headshot .maria-headshot {
    width: 100%;
    margin: 20px 30px 20px 0;
    border: 3px solid #FF4500;
    border-radius: 5px;
    box-shadow: 7px 7px 10px #0e0d0d; }
  @media (max-width: 900px) {
    .maria-about-headshot {
      width: 100%;
      padding: 0 25%;
      margin-bottom: 20px; } }
  @media (max-width: 600px) {
    .maria-about-headshot {
      padding: 0 15%; } }

.sw-main-section-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }
  @media (max-width: 900px) {
    .sw-main-section-container {
      -webkit-flex-direction: column;
              flex-direction: column; } }
  .sw-main-section-container .print-cover-container {
    width: 30%;
    margin: 20px auto 40px auto; }
    .sw-main-section-container .print-cover-container .print-cover {
      width: 100%;
      margin: 20px 30px 20px 0;
      border: 3px solid #FF4500;
      border-radius: 5px;
      box-shadow: 7px 7px 10px #0e0d0d;
      border-color: #0e0d0d; }
    @media (max-width: 900px) {
      .sw-main-section-container .print-cover-container {
        width: 60%; } }
  .sw-main-section-container .sw-info-container {
    width: 70%;
    padding-left: 5%; }
    @media (max-width: 900px) {
      .sw-main-section-container .sw-info-container {
        width: 100%;
        padding-left: 0; } }

.audio-cover-container {
  width: 50%;
  margin: 0 auto 40px auto; }
  .audio-cover-container .audiobook-cover {
    width: 100%;
    margin: 20px 30px 20px 0;
    border: 3px solid #FF4500;
    border-radius: 5px;
    box-shadow: 7px 7px 10px #0e0d0d;
    border-color: #0e0d0d; }
  @media (max-width: 600px) {
    .audio-cover-container {
      width: 70%; } }
  @media (max-width: 375px) {
    .audio-cover-container {
      width: 90%; } }

.audio-sample-container {
  width: 100%;
  margin: 20px 0 50px 0; }
  .audio-sample-container .audiobook-sample {
    width: 50%;
    display: block;
    margin: 20px 30px 20px 0;
    border: 3px solid #FF4500;
    border-radius: 5px;
    box-shadow: 7px 7px 10px #0e0d0d;
    border-color: #0e0d0d; }
    @media (max-width: 900px) {
      .audio-sample-container .audiobook-sample {
        width: 70%; } }
    @media (max-width: 600px) {
      .audio-sample-container .audiobook-sample {
        width: 90%;
        margin: 0;
        border: none;
        border-radius: 0; } }

.heading-container {
  width: 100%;
  min-height: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 20px; }

.hide-show-button {
  border: 3px solid #282626;
  padding: 20px 10px;
  border-radius: 50%;
  background: transparent;
  margin-left: 15px;
  position: relative;
  display: block;
  transition: all 300ms; }
  .hide-show-button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  .hide-show-button:active, .hide-show-button:focus {
    border-color: #FF4500; }
  @media (max-width: 600px) {
    .hide-show-button {
      margin-left: 5px;
      padding: 15px 10px; } }

.hide-show-bar {
  background-color: #282626;
  border-radius: 2px;
  display: block;
  width: 25px;
  height: 3px;
  transition: all 300ms; }
  @media (max-width: 600px) {
    .hide-show-bar {
      width: 20px; } }

.vertical {
  position: absolute;
  -webkit-transform: rotate(90deg) translateX(-3px);
          transform: rotate(90deg) translateX(-3px); }

.vertical-to-horizontal {
  -webkit-transform: rotate(180deg) translateY(3px);
          transform: rotate(180deg) translateY(3px); }

.sw-get-link, .slash {
  font-weight: 600;
  font-size: 1.3rem;
  margin-top: 0; }
  @media (max-width: 600px) {
    .sw-get-link, .slash {
      font-size: 1rem; } }

.slash {
  margin: 0 15px; }
  @media (max-width: 600px) {
    .slash {
      margin: 0 5px; } }

.sw-get-link:hover, .sw-get-link:active, .sw-get-link:focus {
  color: #FF4500; }

.experience-main-section-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }
  @media (max-width: 900px) {
    .experience-main-section-container {
      -webkit-flex-direction: column;
              flex-direction: column; } }
  .experience-main-section-container .experience-image-container {
    width: 30%; }
    @media (max-width: 900px) {
      .experience-main-section-container .experience-image-container {
        width: 100%; } }
  .experience-main-section-container .maria-toronto-star {
    margin: 20px 30px 20px 0;
    border: 3px solid #FF4500;
    border-radius: 5px;
    box-shadow: 7px 7px 10px #0e0d0d; }
    @media (max-width: 900px) {
      .experience-main-section-container .maria-toronto-star {
        width: 40%;
        margin: 20px 30%; } }
    @media (max-width: 600px) {
      .experience-main-section-container .maria-toronto-star {
        width: 60%;
        margin: 20px 20%; } }
  .experience-main-section-container .experience-written-content-container {
    width: 65%;
    margin-left: 5%; }
    @media (max-width: 900px) {
      .experience-main-section-container .experience-written-content-container {
        width: 100%;
        margin-left: 0; } }

.current-work-container {
  margin-top: 20px; }

