// Section flex and widths
.sw-main-section-container {
    width: 100%;
    display: flex;
    flex-direction: row;

    @include tablet {
        flex-direction: column;
    }

    .print-cover-container {
        width: 30%;
        margin: 20px auto 40px auto;
        
        .print-cover {
            width: 100%;
            @include photo-border;
            border-color: $dark-color;
        }

        @include tablet {
            width: 60%;
        }
    }

    .sw-info-container {
        width: 70%;
        padding-left: 5%;

        @include tablet {
            width: 100%;
            padding-left: 0;
        }
    }
}