// Height and padding styles
footer {
    margin-top: 40px;
    border-top: 1px solid $accent-color;
    padding-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .footer-credits {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;

        @include tablet {
            flex-direction: column;
            align-items: center;
        }
    }

    .footer-contact {
        margin: 60px 0;
        text-align: center;
    }
}

.credits-container {
    display: flex;

    @include tablet {
        margin-top: 10px;
    }

    @include mobile {
        flex-direction: column;
        align-items: center;
        margin: 0;
    }
}

//Footer Connect Button styles
.connect-with-maria {
    padding: 15px;
    border: 5px double $accent-color;
    border-radius: 5px;
    font-weight: $semi-bold;
    font-size: 1.2rem;
    letter-spacing: 2px;
    position: relative;
    transition: color 500ms, border-radius 300ms;

    &:hover {
        color: $accent-color;
    }

    &:active, &:focus {
        border-radius: 40px;
    }

    @include mobile {
        font-size: 1rem;
    }
}

//Additional Class Styles
.divider {
    margin: 0 10px;

    @include mobile {
        display: none;
    }
}

.copyright .divider {
    @include tablet {
        display: none;
    }
}

.footer-para {
    font-size: 0.9rem;
    margin: 0;

    @include mobile {
        margin: 7px 0;
    }
}