/* Default styles */
img {
    max-width:100%;
    width: 100%;
    display: block;
}

a {
    text-decoration: none;
    color: $dark-color;

    &:focus {
        outline: none;
    }
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

h1, h2, h3, h4, p, blockquote {
    margin: 0;
}


/* Wrapper styles */
.wrapper {
	max-width: 1600px;
	width: 95%;
	margin: 0 auto;
}

/* Font and Background Styles */
html {
    font-size: 125%;
}

body {
    line-height: 1.6;
    // Background
    background: white;
    font-family: $body-font;
    color: $dark-color;
}

/* Font styles */
h1 {
    font-family: $accent-font;
    font-size: 5rem;
    color: $accent-color;
    text-align: center;
    letter-spacing: 4px;
    margin-top: 20px;

    @include tablet {
        font-size: 3rem;
        padding-top: 50px;
    }

    @include mobile {
        font-size: 2rem;
    }

    @include final {
        font-size: 1.8rem;
    }
}

h2 {
    font-size: 3rem;
    color: $accent-color;
    font-weight: $bold;
    letter-spacing: 2px;

    @include tablet {
        font-size: 2rem;
    }
}

h3 {
    font-size: 1.7rem;
    color: $lighten-dark-color;
    font-weight: $bold;
    letter-spacing: 2px;

    @include mobile {
        font-size: 1.08rem;
    }
}

p {
    font-size: 1.2rem;
    font-weight: $medium;
    letter-spacing: 1px;
    margin: 20px 0;

    @include mobile {
        font-size: 1rem;
    }
}

blockquote {
    font-size: 1.8rem;
    font-weight: $semi-bold;
    letter-spacing: 2px;
    margin-top: 40px;

    @include mobile {
        font-size: 1.4rem;
    }

    @include final {
        font-size: 1.1rem;
    }
}

// Additional class styles 
.bold {
    font-weight: $semi-bold;
}

.italic {
    font-style: italic;
    font-weight: $semi-bold;
    letter-spacing: 2px;
}

.smallest-font {
    font-size: 0.8rem;

    @include tablet {
        text-align: center;
    }
    
}

.italic-not-bolded {
    font-style: italic;
}

.underline {
    border-bottom: 2px solid $accent-color;
    padding: 0 20px;
}

.page-heading {
    padding: 0 20px;
    border-bottom: 2px solid $accent-color;
}

.link {
    font-weight: $bold;
    padding: 3px 0;
    border-bottom: 2px solid transparent;

    &:hover {
        border-bottom: 2px solid $accent-color;
    }

    &:focus, &:active {
        color: $accent-color;
    }
}

.activeLink {
    color: green;
}

.larger-para {
    font-weight: $bold;
    font-size: 1.3rem;
    letter-spacing: 2px;

    @include mobile {
        font-size: 0.9rem;
    }
}

.quote-attribute {
    text-align: right;
    margin-top: 10px;

    @include mobile {
        font-size: 0.9rem;
    }
}

.list-with-bullets {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 15px;
}