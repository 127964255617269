// Flex styles
// Nav Flex
nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 10vh;
    align-items: center;

    @include tablet {
        min-height: auto;
    }

    .nav-list {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;

        @include tablet {
            flex-direction: column;
            z-index: 19;
            position: fixed;
            height: 100vh;
            background: white;
            right: -100%;
            top: 0;
            align-items: center;
            transition: all 300ms;
        }
    }

    .nav-list-item {
        padding: 40px 0;
    }

    .show-nav-list {
        @include tablet {
            right: 0;
        }
    }
}

// Nav Link styles
.nav-link {
    font-size: 1.1rem;
    font-weight: $bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 5px 20px;
    margin: 0 10px;
    border-bottom: 2px solid transparent;

    &:hover {
        border-bottom: 2px solid $accent-color;
    }

    &:focus, &:active {
        border-color: $accent-color;
    }

    @include tablet {
        font-size: 2.4rem;
        margin: 0;
    }

    @include mobile {
        font-size: 1.2rem;
    }
}

//Hamburger Menu styles
.hamburger-menu {
    display: none;
    border: 2px solid transparent;
    border-radius: 50%;
    background: transparent;
    padding: 15px;
    position: absolute;
    right: 2%;
    top: 2%;

    &:active, &:focus {
        border-color: $accent-color;
    }

    .menu-bar {
        border-radius:2px;
        display: block;
        margin: 3px auto;
        width: 30px;
        height: 5px;
        background: $accent-color;
        transition: transform 500ms, opacity 1ms;
    }

    @include tablet {
        display: block;
        z-index: 20;
    }
}

//menu button transition to close button
.transform-bar1 {
    transform: rotate(45deg) translate(3px, 4px);
    border-radius: 0;
}

.transform-bar2 {
    transform: rotate(-45deg) translate(2.5px, -2px);
    border-radius: 0;
}

.transform-bar3 {
    opacity: 0;
}

//Additional class styles
.active-link {
    color: $accent-color;
}