// Fonts
$accent-font: 'Chonburi', cursive;
$body-font: 'Poppins', sans-serif;

// Font Weights
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black: 900;

// Colors
$accent-color: #FF4500;
$dark-color: #0e0d0d;
$lighten-dark-color: lighten($dark-color, 10%);

// Mix-ins
@mixin photo-border {
    margin: 20px 30px 20px 0;
    border: 3px solid $accent-color;
    border-radius: 5px;
    box-shadow: 7px 7px 10px $dark-color;
}

// MEDIA QUERIES
$final-width: 375px;
$mobile-width: 600px;
$tablet-width: 900px;
$desktop-width: 1200px;

@mixin final {
    @media (max-width: #{$final-width})  {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$mobile-width}) {
        @content;
    }
}
@mixin tablet {
    @media (max-width: #{$tablet-width}) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: #{$desktop-width}) {
        @content;
    }
}