.audio-cover-container {
    width: 50%;
    margin: 0 auto 40px auto;
    
    .audiobook-cover {
        width: 100%;
        @include photo-border;
        border-color: $dark-color;
    }

    @include mobile {
        width: 70%;
    }

    @include final {
        width: 90%;
    }
}

.audio-sample-container {
    width: 100%;
    margin: 20px 0 50px 0;
    
    .audiobook-sample {
        width: 50%;
        display: block;
        @include photo-border;
        border-color: $dark-color;

        @include tablet {
            width: 70%;
        }

        @include mobile {
            width: 90%;
            margin: 0;
            border: none;
            border-radius: 0;
        }
    }
}