.heading-container {
    width: 100%;
    min-height: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
}

// Hide/Show button styles
.hide-show-button {
    border: 3px solid $lighten-dark-color;
    padding: 20px 10px;
    border-radius: 50%;
    background: transparent;
    margin-left: 15px;
    position: relative;
    display: block;
    transition: all 300ms;

    &:hover {
        transform: scale(1.1);
    }

    &:active, &:focus {
        border-color: $accent-color;
    }

    @include mobile {
        margin-left: 5px;
        padding: 15px 10px;
    }
}

.hide-show-bar {
    background-color: $lighten-dark-color;
    border-radius:2px;
    display: block;
    width: 25px;
    height: 3px;
    transition: all 300ms;

    @include mobile {
        width: 20px;
    }
}

.vertical {
    position: absolute;
    transform: rotate(90deg) translateX(-3px);
}

// On Click button styles
.vertical-to-horizontal {
    transform: rotate(180deg) translateY(3px);
}